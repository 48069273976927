@import-normalize;
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#root {
  height: 100vh;
}
